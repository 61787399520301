<template>
  <h2 class="section-title">
    <slot>{{ title }}</slot>
  </h2>
</template>

<script>
import px from 'vue-types'

export default {
  name: 'SectionTitle',
  props: {
    title: px.string,
  },
}
</script>

<style lang="scss" scoped></style>
