<template>
  <div>
    <InfoAlert v-if="!roundNum"> Please, select a round from the timeline above </InfoAlert>
    <template v-else>
      <SectionTitle>Round video</SectionTitle>
      <MatchVideoPage :match-id="matchId" :round-num="roundNum" />
      <SectionTitle>Round stats</SectionTitle>
      <MatchRoundStatsPage :match-id="matchId" :round-num="roundNum" />
    </template>
  </div>
</template>

<script>
import px from 'vue-types'

import InfoAlert from '../../components/generic/InfoAlert.vue'
import SectionTitle from '../../components/generic/SectionTitle.vue'

import MatchRoundStatsPage from './RoundStats.vue'
import MatchVideoPage from './Video.vue'
export default {
  name: 'MatchRoundVideoAndStatsPage',
  components: { InfoAlert, MatchRoundStatsPage, MatchVideoPage, SectionTitle },
  props: {
    matchId: px.string.isRequired,
    roundNum: px.number,
  },
}
</script>

<style scoped></style>
