<template>
  <div>
    <div v-if="!roundStats || !roundStats.length" class="text-center text-muted mt-5 mb-5">
      No data for round {{ roundNum + 1 }}
    </div>

    <div v-else class="mt-5 mb-5" v-for="(team, team_id) in roundStats" :key="team_id">
      <TeamCell class="ml-2 mb-2" :id="team.id" :image="team.icon_url" link :name="team.name" variant="row" />

      <RoundStatsTable :class="team.role" :items="team.players" />
    </div>
  </div>
</template>

<script>
import px from 'vue-types'

import RoundStatsTable from '../../Match.old/RoundStatsTable.vue'
import TeamCell from '../../Table/cells/TeamCell.vue'

export default {
  name: 'MatchRoundStats',
  components: {
    TeamCell,
    RoundStatsTable,
  },
  props: {
    roundStats: px.array,
    roundNum: px.integer,
  },
}
</script>

<style lang="scss" scoped>
.atk {
  border-left: 4px solid $danger;
}

.def {
  border-left: 4px solid $info;
}
</style>
