<template>
  <div>
    <p v-if="!apiParams" class="text-danger">Please, select a round from the timeline above</p>
    <Loading v-else-if="loading" />
    <p v-else-if="error" class="text-danger">
      {{ error }}
    </p>
    <MatchRoundStats v-else :round-num="roundNum" :round-stats="data" />
  </div>
</template>

<script>
import px from 'vue-types'
import { mapActions } from 'vuex'

import axios from '@/axios.js'
import Loading from '@/components/generic/Loading.vue'
import MatchRoundStats from '@/components/match/round/Stats.vue'

export default {
  name: 'MatchRoundStatsPage',
  components: { Loading, MatchRoundStats },
  props: {
    matchId: px.string.isRequired,
    roundNum: px.number,
  },
  data: () => ({
    data: null,
    error: null,
    loading: false,
  }),
  computed: {
    apiParams() {
      if (!this.matchId || this.roundNum == null) return null
      return {
        match_id: this.matchId,
        round: this.roundNum,
      }
    },
  },
  watch: {
    apiParams: {
      immediate: true,
      handler(params) {
        if (params) {
          this.fetchData(params)
        }
      },
    },
  },
  beforeDestroy() {
    this.cancelRequest()
  },
  methods: {
    ...mapActions({
      loadRoundStats: 'match/get_round_stats',
    }),
    cancelRequest() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    async fetchData(params) {
      this.cancelRequest()
      this.loading = true
      try {
        this.error = null
        this.cancelTokenSource = axios.CancelToken.source()
        this.data = await this.loadRoundStats({
          ...params,
          config: {
            cancelToken: this.cancelTokenSource.token,
          },
        })
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        this.error = e.message
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
