<template>
  <AppTable class="round-stats-table" :fields="fields" :items="items" :class="tableClass" :busy="busy">
    <template #head(loadout)="data">
      <span v-html="data.label" />
    </template>

    <template #cell(player)="data">
      <img
        class="table-icon agent"
        :class="variant"
        :src="getAgentThumbnail(data.item.agent_name)"
        :alt="data.item.agent_name"
      />
      <div v-if="variant === 'slim'">
        {{ data.item.name }}
      </div>
      <span v-if="variant === 'reg'">{{ data.item.name }}</span>
    </template>

    <template #head(ultimates)="data">
      <span title="Orbs data from start of round" v-b-tooltip.focus.noninteractive>
        {{ data.label }}
      </span>
    </template>

    <template #cell(ultimates)="data">
      <div v-if="data.item.ultimates && data.item.ultimates.count !== null">
        <img
          v-if="data.item.ultimates.count !== null && data.item.ultimates.count === data.item.ultimates.max"
          class="table-icon"
          :src="ultimateImageUrl(data.item.agent_name)"
          alt="READY"
        />
        <span v-else class="text-nowrap">{{ data.item.ultimates.count }} / {{ data.item.ultimates.max }}</span>
      </div>
      <span v-else>N/A</span>
    </template>

    <template #cell(armor)="data">
      <div style="text-align: center">
        <img v-if="data.value" class="table-icon" :src="data.item.armor_url" :alt="data.item.armor_name" />
        <span v-else>-</span>
      </div>
    </template>

    <template #cell(weapon)="data">
      <img
        v-if="data.value"
        class="table-icon"
        :src="`/images/weapons-white/${data.item.weapon_name.toLowerCase()}.png`"
        :alt="data.item.weapon_name"
      />
      <span v-else>-</span>
    </template>

    <template #cell(loadout)="data">
      {{ data.value | to_credits }}
      <div class="small">
        {{ data.item.spent | to_credits }} /
        {{ data.item.remaining | to_credits }}
      </div>
    </template>

    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle" />
        <strong>Loading...</strong>
      </div>
    </template>
  </AppTable>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import AppTable from '@/components/generic/Table.vue'

import { pxId, pxNullable } from '../Map/types.js'

export default {
  name: 'RoundStatsTable',
  components: {
    AppTable,
  },
  props: {
    busy: px.bool.def(false),
    items: px.arrayOf(
      px.shape({
        agent_name: px.string,
        agent_thumbnail: px.string.isRequired,
        armor: pxId(),
        armor_name: pxNullable(px.string),
        assists: px.number.isRequired,
        avr_round_score: px.number.isRequired,
        damage: px.shape({
          total: px.number.isRequired,
        }).loose.isRequired,
        kills: px.number.isRequired,
        loadout: px.number,
        remaining: px.number.isRequired,
        ultimates: pxNullable(
          px.shape({
            count: px.integer.isRequired,
            max: px.integer.isRequired,
          }).loose
        ),
        spent: px.number.isRequired,
        weapon: pxId(),
        weapon_name: px.string,
      }).loose
    ).isRequired,
    variant: px.oneOf(['reg', 'slim']).def('reg'),
  },
  computed: {
    ...mapGetters({
      agentsByName: 'static/agentsByName',
    }),
    fields() {
      return [
        {
          key: 'player',
          label: { reg: 'Team Player', slim: 'Player' }[this.variant],
          class: { reg: '', slim: 'text-center' }[this.variant],
          formatter: (value, key, item) => item.name,
          sortable: true,
          sortByFormatted: true,
        },
        this.hasOrbs
          ? {
              key: 'ultimates',
              label: { reg: 'Ultimates', slim: 'Ults' }[this.variant],
              class: 'text-center',
              formatter: (value, key, item) =>
                item.ultimates && (item.ultimates.count === item.ultimates.max ? 100 : item.ultimates.count),
              sortable: true,
              sortByFormatted: true,
            }
          : null,
        {
          key: 'armor',
          label: { reg: 'Armour', slim: 'A' }[this.variant],
          class: 'text-center',
          formatter: (value, key, item) => item.armor_url,
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'weapon',
          label: { reg: 'Weapon', slim: 'W' }[this.variant],
          class: 'text-center',
          formatter: (value, key, item) => item.weapon_name,
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'loadout',
          label: {
            reg: 'Loadout<div class="small">(Spent/Remaining)</div>',
            slim: 'Load<div class"small">(S/R)</div>',
          }[this.variant],
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'avr_round_score',
          label: 'ACS',
          class: 'text-right',
          formatter: value => (value ? Math.round((value + Number.EPSILON) * 100) / 100 : 'n/a'), // TODO refactor formatters
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'kills',
          label: { reg: 'Kills', slim: 'K' }[this.variant],
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'deaths',
          label: { reg: 'Deaths', slim: 'D' }[this.variant],
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'assists',
          label: { reg: 'Assists', slim: 'A' }[this.variant],
          class: 'text-right',
          sortable: true,
        },
        // {
        //   key: 'hs',
        //   label: 'HS%',
        //   class: 'text-right',
        // }
      ].filter(Boolean)
    },
    hasOrbs() {
      return this.items.some(i => i.ultimates)
    },
    tableClass() {
      switch (this.variant) {
        case 'slim':
          return 'table-sm'
        default:
          return ''
      }
    },
  },
  methods: {
    ultimateImageUrl(agentName) {
      return this.$store.getters['static/getAgentByName'](agentName).abilities.find(ab => ab.slot === 'Ultimate')
        .displayIcon
    },
    getAgentThumbnail(agentName) {
      return this.agentsByName[agentName]['display_icon_url']
    },
  },
}
</script>

<style lang="scss" scoped>
.round-stats-table {
  &::v-deep td {
    vertical-align: middle;
  }

  .table-icon {
    max-width: 60px;
    max-height: 40px;

    &.agent {
      &.slim {
        height: calc(50px - 1.5em);
      }
    }
  }
}
</style>
